import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import _ from 'lodash';
import { withTranslation } from 'react-i18next';

import './controls-list.scss';

import Icon from '../../../Icon';

import { changeInnerAndOuterControl } from '../../../../actions/match';
import { outerControlLabel } from '../../../../helpers';

@withTranslation()
@connect((state) => ({
  match: state.match,
  indicators: state.indicators,
  formations: state.formations,
}), { changeInnerAndOuterControl })

export default class ControlsList extends Component {
  static propTypes = {
    indicators: PropTypes.object,
    match: PropTypes.object,
    changeInnerAndOuterControl: PropTypes.func,
  };

  constructor() {
    super();

    this.state = {
      open: {},
    };

    this.renderControls = this.renderControls.bind(this);
  }

  componentDidMount() {
    const { other, other_goalkeeper } = this.props.indicators;
    const open = {};

    for (let i = 0, l = other.length; i < l; i++) {
      open[other[i]] = false;
    }

    for (let i = 0, l = other_goalkeeper.length; i < l; i++) {
      open[other_goalkeeper[i]] = false;
    }

    this.setState({ open });
  }

  renderControls() {
    const { t, i18n } = this.props;
    const lng = i18n.language;

    const {
      indicators, changeInnerAndOuterControl, match, formations,
    } = this.props;
    const {
      controls, current_values, current_positions, data,
    } = match;
    const values = current_values[controls.side][controls.player];
    const { open } = this.state;
    const current_formation_id = _.get(data, `${controls.side}.formations.lineup.id`, null);
    const current_formation = formations[current_formation_id];
    const position_id_detail = _.get(current_positions, `[${controls.side}].lineup[${controls.player}].position_id_detail`, null);
    const position_id_upper = current_formation.positions.find((i) => +i.position_id_detail === +position_id_detail).position_id;

    const otherIndicators = _.get(indicators, `positions[${position_id_upper}].other`, [])
      .filter((i) => _.get(indicators, `info[${i}].public`, true));
    const disabledIndicators = _.get(indicators, `positions[${position_id_upper}].disabled`, [])
      .filter((i) => _.get(indicators, `info[${i}].public`, true));

    const indicatorsByGroup = _.chain(otherIndicators).filter((control) => disabledIndicators.indexOf(control) === -1).groupBy((indicator) => indicators.info[indicator].class).value();
    const groupsName = _.chain(indicatorsByGroup).keys().sortBy().value();

    const renderGroup = (group) => _
      .chain(group)
      .orderBy((control) => indicators.info[control].name)
      .map((control) => (
        <div key={control} className="control">
          <a className="title" onClick={() => { this.setState({ open: { ...open, [control]: !open[control] } }); }}>
            <Icon name={control} />
            { _.get(indicators, `info[${control}].name_${lng}`, _.get(indicators, `info[${control}].name`, ''))}
          </a>
          <div className={`values ${open[control] ? '' : 'hidden'}`}>
            { [-2, -1, 0, 1, 2].map((value) => (
              <a
                key={value}
                onClick={() => { changeInnerAndOuterControl({ inner: control, outer: value }); }}
                className={`value ${values[control] == value ? 'active' : ''}`}
              >
                { outerControlLabel(value) }
              </a>
            )) }
          </div>
        </div>
      ))
      .value();

    return groupsName.map((group_name) => (
      <div key={group_name} className="controls-group">
        <div className="controls-group-title">{ t(group_name.toUpperCase().replace(/\s/g, '_')) }</div>
        { renderGroup(indicatorsByGroup[group_name]) }
      </div>
    ));
  }

  render() {
    const { t } = this.props;
    return (
      <div className="controls-list">
        <p className="controls-list-help"><a target="_blank" href="https://intercom.help/scoutpad/en/articles/1994409-where-can-i-change-my-scouting-indicators"><Icon name="help-question-mark" /> {t('INDICATOR_HOW_TO_ADD')}</a></p>
        { this.renderControls() }
      </div>
    );
  }
}
