import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import _ from 'lodash';
import VirtualizedSelect from 'react-virtualized-select';
import { withTranslation } from 'react-i18next';
import * as d3 from 'd3';
import {
  deleteIndicator,
  changePrimaryControl, toggleSecondaryControl, selectAllSecondaryControls, deselectAllSecondaryControls,
} from '../../../actions/indicators';
import { fetchIndicators } from '../../../actions/index';

import { getWindowWidth } from '../../../helpers';
import { UPPER_POSITIONS } from '../../../constants';

import './shortcuts.scss';
import CreateShortcutForm from './create-shortcut-form';
import Icon from '../../Icon';

@withTranslation()
@connect((state) => ({
  match: state.match,
  indicators: state.indicators,
  auth: state.auth,
}), {
  changePrimaryControl, toggleSecondaryControl, selectAllSecondaryControls, deselectAllSecondaryControls, deleteIndicator, fetchIndicators,
})

export default class Shortcuts extends Component {
  static propTypes = {
    match: PropTypes.object,
    indicators: PropTypes.object,
    toggleSecondaryControl: PropTypes.func,
    changePrimaryControl: PropTypes.func,
  };

  constructor() {
    super();

    this.renderControlsList = this.renderControlsList.bind(this);
    this.renderControlsSelect = this.renderControlsSelect.bind(this);
    this.renderControlsPreview = this.renderControlsPreview.bind(this);
    this.selectAllControls = this.selectAllControls.bind(this);
    this.deselectAllControls = this.deselectAllControls.bind(this);

    this.state = {
      position_id: 1,
      edit: null,
      isAdd: false,
    };
  }

  changePosition(id) {
    this.setState({
      position_id: id,
    });
  }

  renderPositionSelector() {
    const { i18n } = this.props;
    const lng = i18n.language;
    const { position_id } = this.state;

    return (
      <ul className="positions-selector">
        {
          UPPER_POSITIONS.map((pos) => {
            const activeClass = position_id === pos.id ? 'active' : '';
            const name = _.get(pos, `short_${lng}`, _.get(pos, 'short', null));

            return (
              <li key={pos.id} className={`position ${activeClass}`} onClick={this.changePosition.bind(this, pos.id)}>{ name }</li>
            );
          })
        }
      </ul>
    );
  }

  selectAllControls() {
    const { position_id } = this.state;
    this.props.selectAllSecondaryControls(position_id);
  }

  deselectAllControls() {
    const { position_id } = this.state;
    this.props.deselectAllSecondaryControls(position_id);
  }

  renderControlsList() {
    const {
      toggleSecondaryControl, indicators, t, i18n, deleteIndicator, fetchIndicators, auth,
    } = this.props;
    const isAuthorized = auth && (auth.user.role.tag === 'group_admin' || auth.user.role.tag === 'admin');
    const lng = i18n.language;
    const { info, positions } = indicators;
    const { position_id } = this.state;

    if (_.size(info) <= 3) return null;

    const disabled = _.get(positions, `[${position_id}].disabled`, []);
    const other = _.get(positions, `${position_id}].other`, []);

    const indicatorsByGroup = _.chain(other).groupBy((indicator) => indicators.info[indicator].class).value();
    const groupsName = _.chain(indicatorsByGroup).keys().sortBy().value();

    return groupsName.map((group) => {
      const commonIndicators = _
        .chain(indicatorsByGroup[group])
        .map((control) => info[control])
        .filter((control) => {
          if (+position_id === 1) {
            return (control.Fielder || control.Goalkeeper) && control.public;
          }

          return control.Fielder && control.public;
        })
        .orderBy((control) => control.name)
        .map((control, i) => {
          const isCustom = control.key.startsWith('custom_');

          return (
            <div className="control-name" key={`${control.key}_${i}`}>
              <a
                className={disabled.indexOf(control.key) !== -1 ? 'disabled' : ''}
                onClick={() => {
                  if (!navigator.onLine) {
                    alert(t('NO_CONNECTION'));
                    return;
                  }
                  toggleSecondaryControl({
                    key: control.key,
                    position_id,
                  });
                }}
              >
                {_.get(control, `name_${lng}`, _.get(control, 'name', '-'))}
              </a>
              {isCustom && isAuthorized ? (
                <span
                  className="control-btn control-btn-edit"
                  onClick={() => {
                    this.setState({ edit: control, isAdd: true });
                  }}
                >
                  <Icon name="edit" />
                </span>
              ) : null}
              {isCustom && isAuthorized ? (
                <span
                  className="control-btn control-btn-delete"
                  onClick={() => {
                    if (confirm(t('DELETE_INDICATOR_CONFIRM'))) {
                      deleteIndicator(control._id).then(() => {
                        fetchIndicators();
                      });
                    }
                  }}
                >
                  <Icon name="close" />
                </span>
              ) : null}
            </div>
          );
        })
        .value();

      const groups = _.chunk(commonIndicators, (Math.ceil(_.size(commonIndicators) / 2)));

      return (
        <div className="shortcuts-group" key={group}>
          <h2>{t(group.toUpperCase().split(' ').join('_'))}</h2>
          <div className="controls-list row">
            { groups.map((group, index) => <div key={index} className="col-6">{ group }</div>) }
          </div>
        </div>
      );
    });
  }

  renderControlsSelect() {
    const { info, constant, positions } = this.props.indicators;
    const { t, i18n } = this.props;
    const lng = i18n.language;

    if (_.size(info) <= 2) return null;
    const { position_id } = this.state;
    const primary = _.get(positions, `[${position_id}].primary`, []);

    let options = _.chain(info).map((control, key) => {
      return {
        key,
        ...control,
      };
    }).filter((indicator) => {
      if (position_id === 1) {
        return (indicator.Goalkeeper || indicator.Fielder) && indicator.public;
      }
      return indicator.Fielder && indicator.public;
    });

    options = options
      .map((control) => ({
        label: _.get(control, `name_${lng}`, _.get(control, 'name', '')),
        value: control.key,
      }))
      .filter((option) => constant.indexOf(option.value) === -1)
      .sort((a, b) => {
        if (a.label > b.label) {
          return 1;
        }
        if (a.label < b.label) {
          return -1;
        }
        return 0;
      })
      .value();

    const onChange = (index, value) => {
      if (!navigator.onLine) { alert(t('NO_CONNECTION')); return; }
      const { position_id } = this.state;
      this.props.changePrimaryControl({ index, control: value.value, position_id });
    };

    return primary.map((control, index) => (
      <div key={index} className={`controls-select nth-${index + 1}`}>
        <VirtualizedSelect
          clearable={false}
          searchable
          options={options.filter((option) => (primary.indexOf(option.value) === -1) || (control == option.value))}
          value={control}
          onChange={(value) => { onChange(index, value); }}
        />
      </div>
    ));
  }

  renderControlsPreview() {
    const desktop = getWindowWidth() > 960;
    const mediaQuery = getWindowWidth() > 960 ? 1 : 3 / 4;
    const { indicators, i18n, t } = this.props;
    const lng = i18n.language;

    const { positions } = indicators;
    const { position_id } = this.state;

    const arc = d3.arc().innerRadius(desktop ? 21 : 13).outerRadius(desktop ? 105 : 75);
    const labelArc = d3.arc().innerRadius(desktop ? 30 : 25).outerRadius(desktop ? 107 : 75);

    if (_.size(indicators.info) <= 3) return null;

    const pie = d3.pie().startAngle(-Math.PI / 2).endAngle(Math.PI * 2 - Math.PI / 2).value((d) => 1);

    const renderControl = (key, transform) => {
      let title = _.get(indicators, `info[${key}].short_${lng}`, _.get(indicators, `info[${key}].short`, ''));
      const iconSize = _.cloneDeep(this.props.match.controls.iconSize);
      const wordsLength = title.split(' ').length;

      if (key.startsWith('custom_')) {
        key = 'bars';
      }

      const iconY = -iconSize[1] / 2 * mediaQuery - (wordsLength > 1 ? 12 * mediaQuery : 8 * mediaQuery) - (getWindowWidth() > 960 ? 2 : 4);
      const textY = iconSize[1] / 2.5 * mediaQuery + (wordsLength > 1 ? 4 * mediaQuery : 8 * mediaQuery) - (getWindowWidth() > 960 ? 2 : 4);

      if (wordsLength > 1 && key !== 'relation') {
        title = title.split(' ').map((text, i) => <tspan x={0} y={textY} dy={i === 0 ? '0' : '1em'} key={i}>{ text }</tspan>);
      }

      if (key === 'relation' && lng === 'en') {
        title = [
          <tspan key={1} x={0} y={textY} dy="0">1 vs. 1</tspan>,
          <tspan key={2} x={0} y={textY} dy="1em">Relation</tspan>,
        ];
      }

      return (
        <g>
          <use transform={`translate(${transform})`} width={iconSize[0] * mediaQuery} height={iconSize[1] * mediaQuery} x={-iconSize[0] / 2 * mediaQuery} y={iconY} className="icon" xlinkHref={`/images/sprite.symbol.v107.svg#${key}`} />
          <use transform={`translate(${transform})`} width={iconSize[0] * mediaQuery} height={iconSize[1] * mediaQuery} x={-iconSize[0] / 2 * mediaQuery} y={iconY} className="icon" xlinkHref={`/images/sprite.symbol.v107.svg#${key}`} />
          <text transform={`translate(${transform})`} y={textY}>{ title }</text>
        </g>
      );
    };

    const primary = _.get(positions, `[${position_id}].primary`, []);

    const renderControls = () => pie([...primary, ...indicators.constant]).map((control, key) => (
      <g key={key} className="control">
        <path className="section" d={arc(control)} />
        { renderControl(control.data, labelArc.centroid(control)) }
      </g>
    ));

    return (
      <div className="controls-preview">
        <div className="player-number">18</div>
        <svg className="player-controls">
          <g transform={`translate(${desktop ? 105 : 75}, ${desktop ? 105 : 75})`}>
            <g className="inner">
              { renderControls() }
            </g>
          </g>
        </svg>
      </div>
    );
  }

  render() {
    const { t, auth } = this.props;
    const { isAdd } = this.state;

    const isAuthorized = auth && (auth.user.role.tag === 'group_admin' || auth.user.role.tag === 'admin');

    return (
      <div className="shortcuts">
        <div className="settings-h1 overlay-h1">{t('SHORTCUTS')}</div>
        <div className="position-selector">
          {this.renderPositionSelector()}
        </div>
        <div className="row">
          <div className="col-4 col-xs-12">
            <div className="settings-h2">{t('FAST_SELECTION')}</div>
            <div className="settings-h4">{t('FAST_SELECTION_SUBHEADER')}</div>
            {this.renderControlsSelect()}
          </div>
          <div className="col-4 xs-hidden">
            <div className="settings-h2">&nbsp;</div>
            <div className="settings-h4">{t('PREVIEW_HEADER')}</div>
            { this.renderControlsPreview() }
          </div>
          <div className="col-4 col-xs-12 settings-more-col">
            <div className="settings-h2">{t('CUSTOM_INDICATOR')}</div>
            <div>
              {isAuthorized ? (
                <span
                  className="js-link"
                  onClick={() => {
                    this.setState({ isAdd: true });
                  }}
                >
                  {t('ADD_NEW_INDICATOR')}
                </span>
              ) : (
                <p>{t('INDICATOR_CONTACT_ADMIN')}</p>
              )}
            </div>
            <br />
            <div className="settings-h2">{t('MORE_SECTION')}</div>
            <div className="settings-h4">{t('MORE_SUBHEADING')}</div>

            <div className="settings-select-all">
              <span className="js-link" onClick={this.selectAllControls}>{t('SELECT_ALL')}</span>
              <span className="js-link" onClick={this.deselectAllControls}>{t('DESELECT_ALL')}</span>
            </div>
            {this.renderControlsList()}
          </div>
        </div>
        {isAdd ? (
          <CreateShortcutForm
            edit={this.state.edit}
            onClose={() => {
              this.setState({ isAdd: false, edit: null });
            }}
          />
        ) : null}
      </div>
    );
  }
}
