import _ from 'lodash';
import {
  storageMatchUpdate,
  storageMatchHistory,
  storageEvaluations,
  storagePlayers,
  storageEvaluationsDelete,
} from '../storageMiddleware';

import {
  SET_MATCH,
  TOGGLE_SIDEBAR,
  CHANGE_TAB,
  CHANGE_SUB_TAB,
  CHANGE_TIMELINE,
  CHANGE_POSITION,
  CHANGE_SUBSTITUTION,
  DEACTIVATE_PLAYER,
  CHANGE_SNAPSHOT,
  CHANGE_SIZE,
  CHANGE_OUTER_CONTROL,
  CHANGE_INNER_CONTROL,
  CHANGE_PLAYER_CONTROL,
  HIDE_CONTROLS,
  CHANGE_INNER_AND_OUTER_CONTROL,
  CHANGE_TEAM_NOTES,
  MATCH_CHANGE_PLAYER_NOTE,
  ADD_NEW_PLAYER,
  ADD_PLAYER_DB,
  SWAP_PLAYERS,
  ADD_PLAYER_TOGGLE,
  CHANGE_GOALS,
  CHANGE_JERSEY,
  CREATE_SNAPSHOT,
  REMOVE_SNAPSHOT,
  REMOVE_PLAYER_STORY,
  CHANGE_FORMATION,
  CHANGE_FORMATION_POSITION,
  SELECT_FORMATION_POSITION,
  SELECT_SUBSTITUTION,
  SAVE_DEFAULT_LINEUP,
  INCREASE_GOAL,
  DECREASE_GOAL,
  SET_GOALS_FOR_PERIOD,
  SET_SUBSTITUTION_TIME,
} from '../actions/match';
import { CHANGE_FINAL_RATING, CLEAR_PDF } from '../actions/pdf';
import { DELETE_PLAYER_FROM_MATCH, UPDATE_PLAYER_IN_MATCH } from '../actions/favorites';

const INITIAL_STATE = {
  data: {},
  layout: {
    sidebar: true,
    width: 0,
    height: 0,
    mediaQuery: 'desktop',
  },
  sidebar: {
    tabs: ['Players', 'Teamnotes', 'History'],
    tab: 'Players',
    subTabs: ['home', 'away'],
    subTab: 'home',
  },
  periods: {
    1: {
      label: 'HALF1',
      startTime: 0,
    },
    2: {
      label: 'HALF2',
      startTime: 45,
    },
    3: {
      label: 'EXTRA1',
      startTime: 90,
    },
    4: {
      label: 'EXTRA2',
      startTime: 105,
    },
  },
  timeline: 100000,
  current_positions: {},
  current_values: {},
  snapshot: 'lineup',
  controls: {
    player: null,
    side: null,
    inner: null,
    outer: null,
    iconSize: [22, 22],
  },
  addPlayer: false,
  positionSelected: {
    players: [],
    position: {},
    side: null,
  },
  substitutionSelected: {
    side: null,
    index: null,
  },
};

const parseStartValues = (values, timeline) => (_.chain(values)
  .map((player, i) => {
    const playerValues = player[timeline] || {};
    return {
      player_id: i,
      ...playerValues,
    };
  }).keyBy('player_id')
  .value());

export default function (state = INITIAL_STATE, action) {
  const { snapshot, timeline } = state;
  let clone;

  switch (action.type) {
    case CLEAR_PDF: {
      return {
        ...INITIAL_STATE,
      };
    }
    case SET_MATCH: {
      const { match } = action;

      if (!match) return { ...INITIAL_STATE };

      const prevTimeline = localStorage.getItem(`timeline_${match._id}`) || 100000;

      const current_values_home = parseStartValues(match.home.values, prevTimeline);
      const current_values_away = parseStartValues(match.away.values, prevTimeline);
      let side = null;
      let isPlayerExist = false;

      let sidebar = {
        tabs: ['Players', 'Teamnotes', 'History'],
        tab: 'Players',
        subTabs: ['home', 'away'],
        subTab: 'home',
      };

      if (match.type === 'single' && match.player_id) {
        const { home, away } = match;
        const isHome = !!_.get(home, `players[${match.player_id}]`, false);
        const isAway = !!_.get(away, `players[${match.player_id}]`, false);

        if (isHome || isAway) {
          isPlayerExist = true;
          side = isHome ? 'home' : 'away';
        }

        sidebar = {
          tabs: ['History', 'Teamnotes'],
          tab: 'History',
          /* subTabs: ['home', 'away'],
          subTab: 'home', */
        };
      }

      const ma = {
        ...state,
        timeline: +prevTimeline ? parseInt(prevTimeline) : 0,
        data: {
          ...match,
          last_event_id: match.last_event_id || 0,
        },
        current_positions: {
          home: _.cloneDeep(match.home.positions),
          away: _.cloneDeep(match.away.positions),
        },
        type: isPlayerExist ? match.type : 'match',
        player_id: isPlayerExist ? match.player_id : null,
        side,
        current_values: {
          home: current_values_home,
          away: current_values_away,
        },
        layout: {
          ...state.layout,
        },
        sidebar: {
          ...state.sidebar,
          ...sidebar,
        },
      };

      return ma;
    }

    case SAVE_DEFAULT_LINEUP: {
      return {
        ...state,
        data: {
          ...state.data,
          [action.side]: {
            ...state.data[action.side],
            defaultLineup: {
              ...state.data[action.side].defaultLineup,
              lineup: action.lineup,
              formation_id: action.formation_id,
            },
          },
        },
      };
    }

    case TOGGLE_SIDEBAR: {
      return {
        ...state,
        layout: {
          ...state.layout,
          sidebar: action.status,
        },
      };
    }

    case CHANGE_SIZE: {
      return {
        ...state,
        layout: {
          ...state.layout,
          width: action.width,
          height: action.height,
          mediaQuery: action.mediaQuery,
        },
      };
    }

    case CHANGE_TAB: {
      return {
        ...state,
        sidebar: {
          ...state.sidebar,
          tab: action.active,
        },
      };
    }

    case CHANGE_SUB_TAB: {
      return {
        ...state,
        sidebar: {
          ...state.sidebar,
          subTab: action.active,
        },
      };
    }

    case CHANGE_SNAPSHOT: {
      return {
        ...state,
        snapshot: action.active,
      };
    }

    case MATCH_CHANGE_PLAYER_NOTE:
      clone = _.cloneDeep(state);
      const {
        side, player_id, time, note, tags,
      } = action;

      let temp = clone.data[side].values[player_id][time];

      if (time !== 'Note' && temp && (temp.notes || (Array.isArray(temp.event_type) && temp.event_type.length))) {
        temp.notes = note;
        if (tags && Array.isArray(tags)) {
          temp.tags = tags;
        }
      } else {
        clone.data[side].values[player_id][0] = { notes: note, player_id, event_id: -1 };
        temp = clone.data[side].values[player_id][0];
        if (tags && Array.isArray(tags)) {
          temp.tags = tags;
        }
      }

      storageEvaluations({
        side,
        time: time !== 'Note' ? time : 0,
        player_id,
        event_type: temp && temp.event_type || [],
        tags: temp && temp.tags || [],
        key: 'notes',
        value: note,
        match_id: clone.data._id,
        team_id: clone.data[side].team_id,
      }, null).then(() => {
        return storageMatchUpdate({ match: clone.data, skipServerUpdate: true });
      }).then(() => {
        return storageMatchHistory(clone.data._id, action.type, {
          time, snapshot, player_id, note,
        });
      });

      return clone;

    case CHANGE_FINAL_RATING: {
      clone = _.cloneDeep(state);
      const { side, player_id, value } = action;

      clone.data[side].final_rating = clone.data[side].final_rating || {};

      if (value) {
        clone.data[side].final_rating[player_id] = value;
      } else {
        delete clone.data[side].final_rating[player_id];
      }

      storageMatchUpdate({ match: clone.data }).then(() => {
        return storageMatchHistory(clone.data._id, action.type, {
          snapshot, side, player_id, time: timeline, value,
        });
      });

      return clone;
    }

    case CHANGE_TIMELINE: {
      clone = _.cloneDeep(state);
      clone.timeline = +action.time;

      localStorage.setItem(`timeline_${state.data._id}`, action.time);

      clone.current_values = {
        home: _
          .chain(state.data.home.values)
          .map((player, i) => (player[action.time] ? player[action.time] : { player_id: i }))
          .keyBy('player_id')
          .cloneDeep()
          .value(),
        away: _
          .chain(state.data.away.values)
          .map((player, i) => (player[action.time] ? player[action.time] : { player_id: i }))
          .keyBy('player_id')
          .cloneDeep()
          .value(),
      };

      if (clone.timeline === 100001) {
        clone.data.is_in_progress = true;
        storageMatchUpdate({ match: clone.data });
      }

      return clone;
    }

    case CHANGE_POSITION: {
      const { left, top } = action;
      const { width, height } = state.layout;

      clone = _.cloneDeep(state);

      const setPositionValue = (position) => {
        const positionClone = _.size(position) ? _.cloneDeep(position) : { player_id: action.information.key };
        positionClone.active = true;
        positionClone.substitution = false;
        positionClone.coordinates = { left: left / width, top: top / height };
        return positionClone;
      };

      clone.current_positions[action.side][snapshot][action.information.key] = setPositionValue(state.current_positions[action.side][snapshot][action.information.key]);
      clone.data[action.side].positions[snapshot][action.information.key] = setPositionValue(state.data[action.side].positions[snapshot][action.information.key]);
      clone.data[action.side].positions[snapshot][action.information.key].event_id = clone.data.last_event_id;
      clone.data.last_event_id = ++clone.data.last_event_id;

      storageMatchUpdate({ match: clone.data }).then(() => {
        return storageMatchHistory(clone.data._id, action.type, {
          snapshot, side: action.side, time: timeline, player_id: action.information.key,
        });
      });
      return clone;
    }

    case CHANGE_SUBSTITUTION: {
      clone = _.cloneDeep(state);

      const setSubstitutionPosition = (position) => {
        const positionClone = position ? _.cloneDeep(position) : { player_id: action.information.key };
        positionClone.active = false;
        positionClone.substitution = true;
        positionClone.coordinates = { left: null, top: null };

        delete positionClone.position_id;
        delete positionClone.position_id_detail;

        return positionClone;
      };

      if (clone.snapshot !== 'lineup') {
        return clone;
      }

      _.forEach(_.keys(clone.current_positions[action.side]), (snapshotName) => {
        clone.current_positions[action.side][snapshotName][action.information.key] = setSubstitutionPosition(state.current_positions[action.side][snapshotName][action.information.key]);
        clone.data[action.side].positions[snapshotName][action.information.key] = setSubstitutionPosition(clone.data[action.side].positions[snapshotName][action.information.key]);
        clone.data[action.side].positions[snapshotName][action.information.key].event_id = clone.data.last_event_id;
        clone.data.last_event_id = ++clone.data.last_event_id;
      });

      storageMatchUpdate({ match: clone.data }).then(() => {
        storageMatchHistory(clone.data._id, action.type, {
          side: action.side, time: timeline, snapshot, player_id: action.information.key,
        });
      });
      return clone;
    }

    case DEACTIVATE_PLAYER: {
      clone = _.cloneDeep(state);

      if (state.data[action.side].formations && state.data[action.side].formations[snapshot]) {
        const formationPositionIndex = _.findIndex(state.data[action.side].formations[snapshot].positions, (p) => p.player_id == action.information.key);

        if (formationPositionIndex > -1) {
          clone.data[action.side].formations[snapshot].positions[formationPositionIndex].player_id = null;
        }
      }

      const setDeactivatePosition = (position) => {
        const positionClone = position ? _.cloneDeep(position) : { player_id: action.information.key };
        positionClone.active = false;
        positionClone.substitution = false;
        positionClone.coordinates = { left: null, top: null };

        delete positionClone.position_id;
        delete positionClone.position_id_detail;

        return positionClone;
      };

      clone.current_positions[action.side][snapshot][action.information.key] = setDeactivatePosition(state.current_positions[action.side][snapshot][action.information.key]);
      clone.data[action.side].positions[snapshot][action.information.key] = setDeactivatePosition(state.data[action.side].positions[snapshot][action.information.key]);
      clone.data[action.side].positions[snapshot][action.information.key].event_id = clone.data.last_event_id;
      clone.data.last_event_id = ++clone.data.last_event_id;

      storageMatchUpdate({ match: clone.data }).then(() => {
        storageMatchHistory(clone.data._id, action.type, {
          snapshot, time: timeline, side: action.side, player_id: action.information.key,
        });
      });
      return clone;
    }

    case CHANGE_OUTER_CONTROL: {
      clone = _.cloneDeep(state);

      const tl = action.timeline || +state.timeline || 100000;
      const currentValue = state.data[state.controls.side].values[state.controls.player][tl];

      clone.data[state.controls.side].values[state.controls.player][tl] = setOuterControlValue(action, state, currentValue, clone.data.last_event_id);

      clone.controls.outer = action.value;
      clone.current_values[state.controls.side][state.controls.player][state.controls.inner] = action.value;

      if (action.tags && action.tags.length) {
        clone.current_values[state.controls.side][state.controls.player].tags = action.tags;
      } else {
        delete clone.current_values[state.controls.side][state.controls.player].tags;
        delete clone.data[state.controls.side].values[state.controls.player][tl].tags;
      }

      if (action.event_type && action.event_type.length) {
        clone.current_values[state.controls.side][state.controls.player].event_type = action.event_type;
      } else {
        delete clone.current_values[state.controls.side][state.controls.player].event_type;
        delete clone.data[state.controls.side].values[state.controls.player][tl].event_type;

        if (!clone.current_values[state.controls.side][state.controls.player].notes) {
          delete clone.current_values[state.controls.side][state.controls.player].notes;
          delete clone.data[state.controls.side].values[state.controls.player][tl].notes;
        }
      }

      clone.current_values[state.controls.side][state.controls.player].event_id = clone.data.last_event_id;
      clone.data.last_event_id = ++clone.data.last_event_id;

      storageEvaluations({
        side: state.controls.side,
        time: tl,
        player_id: state.controls.player,
        key: state.controls.inner,
        value: action.value,
        event_type: action.event_type,
        tags: action.tags,
        match_id: clone.data._id,
        team_id: clone.data[state.controls.side].team_id,
      }).then(() => {
        return storageMatchUpdate({ match: clone.data, skipServerUpdate: true });
      }).then(() => {
        return storageMatchHistory(clone.data._id, action.type, {
          side: state.controls.side, time: tl, player_id: state.controls.player, control: state.controls.inner, value: action.value,
        });
      });
      return clone;
    }

    case CHANGE_INNER_CONTROL: {
      clone = _.cloneDeep(state);
      clone.controls.inner = action.value;
      clone.controls.outer = null;
      return clone;
    }

    case CHANGE_INNER_AND_OUTER_CONTROL: {
      clone = _.cloneDeep(state);
      const setInnerAndOuterControlValue = (value) => {
        const valueClone = _.cloneDeep(value || {
          player_id: state.controls.player,
        });

        valueClone[action.inner] = action.outer;
        valueClone.event_id = clone.data.last_event_id;

        return valueClone;
      };

      const tl = +state.timeline || 100000;

      clone.data[state.controls.side].values[state.controls.player][tl] = setInnerAndOuterControlValue(state.data[state.controls.side].values[state.controls.player][+state.timeline || 100000]);

      clone.current_values[state.controls.side][state.controls.player][action.inner] = action.outer;
      clone.current_values[state.controls.side][state.controls.player].event_id = clone.data.last_event_id;
      clone.data.last_event_id = ++clone.data.last_event_id;

      storageEvaluations({
        side: state.controls.side,
        time: tl,
        player_id: state.controls.player,
        key: action.inner,
        value: action.outer,
        match_id: clone.data._id,
        team_id: clone.data[state.controls.side].team_id,
      }, null).then(() => {
        return storageMatchUpdate({ match: clone.data, skipServerUpdate: true });
      }).then(() => {
        storageMatchHistory(clone.data._id, action.type, {
          side: state.controls.side, time: tl, player_id: state.controls.player, control: action.inner, value: action.outer,
        });
      });

      return clone;
    }

    case CHANGE_PLAYER_CONTROL: {
      return {
        ...state,
        controls: {
          ...state.controls,
          player: action.player_id,
          side: action.side,
        },
      };
    }

    case HIDE_CONTROLS: {
      clone = _.cloneDeep(state);
      clone.controls.player = null;
      clone.controls.side = null;
      clone.controls.inner = null;
      clone.controls.outer = null;
      clone.positionSelected = {
        players: [],
        position: {},
        side: null,
      };
      clone.substitutionSelected = {
        side: null,
        index: null,
      };

      return clone;
    }

    case CHANGE_TEAM_NOTES: {
      clone = _.cloneDeep(state);
      if (action.home !== undefined) {
        clone.data.home.notes = action.home;
      }
      if (action.away !== undefined) {
        clone.data.away.notes = action.away;
      }

      storageMatchUpdate({ match: clone.data }).then(() => {
        return storageMatchHistory(clone.data._id, action.type, { home: action.home, away: action.away });
      });
      return clone;
    }

    case ADD_NEW_PLAYER: {
      const {
        first_name, last_name, jersey, position_id, foot, birth_date,
      } = action;
      clone = _.cloneDeep(state);
      const team_id = _.get(clone, `data[${clone.sidebar.subTab}].team_id`, null);

      const id = `new-${team_id}_${jersey}_${clone.data.user_id}`;

      if (!clone.data[clone.sidebar.subTab].players) {
        clone.data[clone.sidebar.subTab].players = {};
      }

      clone.data[clone.sidebar.subTab].players[id] = {
        new: true,
        player_id: id,
        information: {
          active: false,
          first_name,
          jersey,
          key: id,
          last_name,
          position_id,
          team_id: clone.data[clone.sidebar.subTab].team_id,
          foot,
          birth_date,
        },
      };

      _.forEach(_.keys(clone.data[clone.sidebar.subTab].positions), (snapshot) => {
        clone.data[clone.sidebar.subTab].positions[snapshot] = {
          ...clone.data[state.sidebar.subTab].positions[snapshot],
          [id]: {
            player_id: id, coordinates: { top: null, left: null }, substitution: false, active: false,
          },
        };

        clone.current_positions[clone.sidebar.subTab][snapshot] = {
          ...clone.current_positions[clone.sidebar.subTab][snapshot],
          [id]: {
            player_id: id, coordinates: { top: null, left: null }, substitution: false, active: false,
          },
        };
      });

      clone.data[clone.sidebar.subTab].values = {
        ...clone.data[state.sidebar.subTab].values,
        [id]: {
          100000: { player_id: id },
        },
      };

      clone.current_values[clone.sidebar.subTab] = {
        ...clone.current_values[clone.sidebar.subTab],
        [id]: { player_id: id },
      };

      clone.addPlayer = false;

      storagePlayers({
        player: {
          first_name, last_name, jersey, position_id, foot, birth_date, player_id: id,
        },
        team_id: clone.data[clone.sidebar.subTab].team_id,
        type: 'ADD',
        match_id: clone.data._id,
      })
        .then(() => {
          return storageMatchUpdate({ match: clone.data });
        }).then(() => {
          return storageMatchHistory(clone.data._id, action.type, { player: clone.data[clone.sidebar.subTab].players[id] });
        });

      return clone;
    }

    case ADD_PLAYER_DB: {
      const { player } = action;
      clone = _.cloneDeep(state);

      const newPlayer = {
        new: true,
        player_id: player.key,
        information: {
          ...player,
          team_id: clone.data[clone.sidebar.subTab].team_id,
        },
      };

      _.setWith(clone, `data[${clone.sidebar.subTab}].players[${player.key}]`, newPlayer, Object);

      _.forEach(_.keys(clone.data[clone.sidebar.subTab].positions), (snapshot) => {
        clone.data[clone.sidebar.subTab].positions[snapshot] = {
          ...clone.data[state.sidebar.subTab].positions[snapshot],
          [player.key]: {
            player_id: player.key, coordinates: { top: null, left: null }, substitution: false, active: false,
          },
        };
      });

      _.forEach(_.keys(clone.current_positions[clone.sidebar.subTab]), (snapshot) => {
        clone.current_positions[clone.sidebar.subTab][snapshot] = {
          ...clone.current_positions[clone.sidebar.subTab][snapshot],
          [player.key]: {
            player_id: player.key, coordinates: { top: null, left: null }, substitution: false, active: false,
          },
        };
      });

      clone.data[clone.sidebar.subTab].values = {
        ...clone.data[state.sidebar.subTab].values,
        [player.key]: {
          100000: { player_id: player.key },
        },
      };

      clone.current_values[clone.sidebar.subTab] = {
        ...clone.current_values[clone.sidebar.subTab],
        [player.key]: { player_id: player.key },
      };

      clone.addPlayer = false;

      storagePlayers({
        player, team_id: clone.data[clone.sidebar.subTab].team_id, type: 'MODIFY', match_id: clone.data._id,
      })
        .then(() => {
          return storageMatchUpdate({ match: clone.data });
        })
        .then(() => {
          return storageMatchHistory(clone.data._id, action.type, { player: clone.data[clone.sidebar.subTab].players[player.key] });
        });

      return clone;
    }

    case SWAP_PLAYERS: {
      clone = _.cloneDeep(state);
      const from_position = _.cloneDeep(state.current_positions[action.from.side][snapshot][action.from.player_id]);
      const to_position = _.cloneDeep(state.current_positions[action.to.side][snapshot][action.to.player_id]);

      const swapsCount = _.filter(state.data[action.from.side].positions[snapshot], (player) => player.swap && player.swapTime).length / 2;

      // 1. substitute one player to another doesn't work

      /* Swap cancel
      *  Dismiss substitution from already substituted players
      *  */
      if (
        (from_position.substitution || to_position.substitution)
        && (from_position.swap === to_position.player_id && to_position.swap === from_position.player_id)
        && snapshot === 'lineup'
      ) {
        clone.data[action.from.side].positions[snapshot][action.from.player_id].swap = null;
        clone.data[action.from.side].positions[snapshot][action.from.player_id].swapTime = null;
        clone.data[action.to.side].positions[snapshot][action.to.player_id].swap = null;
        clone.data[action.to.side].positions[snapshot][action.to.player_id].swapTime = null;

        clone.current_positions[action.from.side][snapshot][action.from.player_id].swap = null;
        clone.current_positions[action.from.side][snapshot][action.from.player_id].swapTime = null;
        clone.current_positions[action.to.side][snapshot][action.to.player_id].swap = null;
        clone.current_positions[action.to.side][snapshot][action.to.player_id].swapTime = null;

        storageMatchUpdate({ match: clone.data }).then(() => {
          return storageMatchHistory(clone.data._id, action.type, {
            time: timeline, snapshot, from_position, to_position,
          });
        });
        return clone;
      }

      /* Swap limit  */
      if (swapsCount >= 5 && (action.from.side === action.to.side) && (from_position.substitution || to_position.substitution) && state.data.league_id !== 'FG') {
        storageMatchUpdate({ match: clone.data }).then(() => {
          return storageMatchHistory(clone.data._id, action.type, {
            time: timeline, snapshot, from_position, to_position,
          });
        });
        return clone;
      }

      /* Swap disable for diff teams */
      if (
        (action.from.side !== action.to.side && (from_position.substitution || to_position.substitution))
        || (action.from.side !== action.to.side && state.snapshot === 'lineup' && ((state.data.home.formations[snapshot] && state.data.home.formations[snapshot].id) || (state.data.away.formations[snapshot] && state.data.away.formations[snapshot].id)))
      ) {
        storageMatchUpdate({ match: clone.data }).then(() => {
          return storageMatchHistory(clone.data._id, action.type, {
            time: timeline, snapshot, from_position, to_position,
          });
        });
        return clone;
      }

      const setSwapPosition = (snapshot, fromTo) => {
        const opositeFromTo = fromTo === 'to' ? 'from' : 'to';
        const oldPosition = state.current_positions[action[opositeFromTo].side][snapshot][action[opositeFromTo].player_id];
        const newPosition = state.current_positions[action[fromTo].side][snapshot][action[fromTo].player_id];

        let swap = null;
        if (+state.timeline !== 100000) {
          if (snapshot === 'lineup') {
            swap = state.current_positions[action[opositeFromTo].side][snapshot][action[opositeFromTo].player_id].substitution || newPosition.substitution
              ? action[fromTo].player_id : null;
          } else {
            swap = oldPosition.swap;
          }
        }

        const positionClone = newPosition ? _.cloneDeep({
          ...newPosition,
          player_id: action[opositeFromTo].player_id,
          swap,
          swapTime: swap ? timeline : null,
        }) : {
          player_id: action[opositeFromTo].player_id,
          swap,
          active: false,
          substitution: false,
          coordinates: {
            left: null,
            top: null,
          },
          swapTime: swap ? timeline : null,
        };

        if (newPosition.active && oldPosition.active && oldPosition.swap) {
          positionClone.swap = oldPosition.swap;
          positionClone.swapTime = oldPosition.swapTime;
        }

        if (newPosition && oldPosition && oldPosition.active && newPosition.substitution) {
          const formationPositionIndex = _.findIndex(state.data[action[opositeFromTo].side].formations[snapshot].positions, ((p) => p.player_id == oldPosition.player_id));
          clone.data[action[opositeFromTo].side].formations[snapshot].positions[formationPositionIndex].player_id = newPosition.player_id;

          delete positionClone.position_id;
          delete positionClone.position_id_detail;
        }

        return positionClone;
      };

      // Swapping positions

      // Change from positions
      clone.current_positions[action.from.side][snapshot][action.from.player_id] = setSwapPosition(snapshot, 'to');
      clone.data[action.from.side].positions[snapshot][action.from.player_id] = setSwapPosition(snapshot, 'to');
      clone.data[action.from.side].positions[snapshot][action.from.player_id].event_id = clone.data.last_event_id;
      clone.data.last_event_id = ++clone.data.last_event_id;

      // Change to positions
      clone.current_positions[action.to.side][snapshot][action.to.player_id] = setSwapPosition(snapshot, 'from');
      clone.data[action.to.side].positions[snapshot][action.to.player_id] = setSwapPosition(snapshot, 'from');
      clone.data[action.to.side].positions[snapshot][action.to.player_id].event_id = clone.data.last_event_id;
      clone.data.last_event_id = ++clone.data.last_event_id;

      // UPDATE FORMATIONS
      if (action.to.side === action.from.side && clone.data[action.to.side].formations[snapshot] && clone.data[action.to.side].formations[snapshot].id) {
        if (from_position.active) {
          const fromPositionPlayerIndex = _.findIndex(state.data[action.to.side].formations[snapshot].positions, (p) => p.player_id == from_position.player_id);
          clone.data[action.to.side].formations[snapshot].positions[fromPositionPlayerIndex].player_id = to_position.player_id;
        }
        if (to_position.active) {
          const toPositionPlayerIndex = _.findIndex(state.data[action.to.side].formations[snapshot].positions, (p) => p.player_id == to_position.player_id);
          clone.data[action.to.side].formations[snapshot].positions[toPositionPlayerIndex].player_id = from_position.player_id;
        }
      }

      storageMatchUpdate({ match: clone.data }).then(() => {
        storageMatchHistory(clone.data._id, action.type, {
          time: timeline, snapshot, from_position, to_position,
        });
      });

      return clone;
    }

    case ADD_PLAYER_TOGGLE: {
      return {
        ...state,
        addPlayer: action.status,
      };
    }

    case CHANGE_GOALS: {
      clone = _.cloneDeep(state);
      clone.data[action.key] = action.value;

      storageMatchUpdate({ match: clone.data }).then(() => {
        storageMatchHistory(clone.data._id, action.type, { time: timeline, side: action.key, goals: action.value });
      });

      return clone;
    }

    case SET_SUBSTITUTION_TIME: {
      const newMatch = {
        ...state,
        data: {
          ...state.data,
          [action.payload?.dir]: action.payload?.time,
        },
      };

      storageMatchUpdate({ match: newMatch.data }).then(() => {
        storageMatchHistory(newMatch.data._id, action.type, { time: timeline, dir: action.payload?.dir });
      });

      return newMatch;
    }

    case INCREASE_GOAL: {
      clone = _.cloneDeep(state);
      clone.data[action.key] = action.value;

      let property = action.side === 'home' ? 'homegoals' : 'awaygoals';

      if (!clone.data[property]) {
        clone.data[property] = 1;
      } else {
        clone.data[property] = clone.data[property] + 1;
      }

      if (action.time < 204500 && timeline >= 204500) {
        property = action.side === 'home' ? 'homegoals_ht' : 'awaygoals_ht';
        if (typeof +clone.data[property] === 'number') {
          clone.data[property] += 1;
        }
      }

      storageMatchUpdate({ match: clone.data }).then(() => {
        storageMatchHistory(clone.data._id, action.type, { time: timeline });
      });

      return clone;
    }

    case DECREASE_GOAL: {
      clone = _.cloneDeep(state);
      clone.data[action.key] = action.value;

      let property = action.side === 'home' ? 'homegoals' : 'awaygoals';

      if (!clone.data[property]) {
        clone.data[property] = 0;
      } else {
        clone.data[property] = clone.data[property] - 1;
      }

      if (action.time < 204500 && timeline >= 204500) {
        property = action.side === 'home' ? 'homegoals_ht' : 'awaygoals_ht';
        if (typeof +clone.data[property] === 'number' && clone.data[property] > 0) {
          clone.data[property] -= 1;
        }
      }

      storageMatchUpdate({ match: clone.data }).then(() => {
        storageMatchHistory(clone.data._id, action.type, { time: timeline });
      });

      return clone;
    }

    case CHANGE_JERSEY: {
      clone = _.cloneDeep(state);
      clone.data[state.controls.side].players[state.controls.player].information.jersey = action.number;

      storagePlayers({
        player: { ...clone.data[state.controls.side].players[state.controls.player].information, player_id: clone.data[state.controls.side].players[state.controls.player].information.key },
        team_id: clone.data[state.controls.side].team_id,
        type: 'MODIFY',
        match_id: clone.data._id,
      })
        .then(() => {
          return storageMatchUpdate({ match: clone.data });
        })
        .then(() => {
          return storageMatchHistory(clone.data._id, action.type, { time: timeline, player_id: state.controls.player, jersey: action.number });
        });

      return clone;
    }

    case UPDATE_PLAYER_IN_MATCH: {
      clone = _.cloneDeep(state);

      clone.data[state.controls.side].players[state.controls.player].information = {
        ...clone.data[state.controls.side].players[state.controls.player].information,
        ...action.payload,
      };

      storagePlayers({
        player: { ...clone.data[state.controls.side].players[state.controls.player].information, player_id: clone.data[state.controls.side].players[state.controls.player].information.key },
        team_id: clone.data[state.controls.side].team_id,
        type: 'MODIFY',
        match_id: clone.data._id,
      })
        .then(() => {
          return storageMatchUpdate({ match: clone.data });
        })
        .then(() => {
          return storageMatchHistory(clone.data._id, action.type, { time: timeline, player_id: state.controls.player, jersey: action.number });
        });

      return clone;
    }

    case DELETE_PLAYER_FROM_MATCH: {
      clone = _.cloneDeep(state);

      const { player_id } = action.payload;

      let side = '';
      ['away', 'home'].forEach((s) => {
        if (_.get(clone, `data.${s}.players[${player_id}]`, null)) {
          side = s;
        }
      });
      if (!side) return clone;

      const information = { ...clone.data[side].players[player_id].information, player_id: clone.data[side].players[player_id].information.key };

      delete clone.data[side].positions.lineup[player_id];
      delete clone.data[side].players[player_id];
      delete clone.data[side].values[player_id];
      delete clone.current_positions[side].lineup[player_id];
      delete clone.current_values[side][player_id];

      for (const key in clone.data[side].positions.lineup) {
        const pos = clone.data[side].positions.lineup[key];
        if (pos.swap === player_id) {
          delete pos.swap;
          delete pos.swapTime;
          delete pos.event_id;
          pos.substitution = false;
        }
      }

      for (const key in clone.current_positions[side].lineup) {
        const pos = clone.current_positions[side].lineup[key];
        if (pos.swap === player_id) {
          delete pos.swap;
          delete pos.swapTime;
          delete pos.event_id;
          pos.substitution = false;
        }
      }

      clone.controls.player = null;
      clone.controls.side = null;
      clone.controls.inner = null;
      clone.controls.outer = null;
      clone.positionSelected = {
        players: [],
        position: {},
        side: null,
      };
      clone.substitutionSelected = {
        side: null,
        index: null,
      };

      storagePlayers({
        player: information,
        team_id: clone.data[state.controls.side].team_id,
        type: 'DELETE',
        match_id: clone.data._id,
      })
        .then(() => {
          return storageMatchUpdate({ match: clone.data });
        })
        .then(() => {
          return storageMatchHistory(clone.data._id, action.type, { time: timeline, player_id: state.controls.player, jersey: action.number });
        });

      return clone;
    }

    case CREATE_SNAPSHOT: {
      clone = _.cloneDeep(state);
      const { snapshotName } = action;

      _.set(clone, `data.home.positions[${snapshotName}]`, _.cloneDeep(clone.data.home?.positions?.[snapshot]));
      _.set(clone, `data.away.positions[${snapshotName}]`, _.cloneDeep(clone.data.away?.positions?.[snapshot]));
      _.set(clone, `current_positions.home[${snapshotName}]`, _.cloneDeep(clone.current_positions?.home?.[snapshot]));
      _.set(clone, `current_positions.away[${snapshotName}]`, _.cloneDeep(clone.current_positions?.away?.[snapshot]));

      _.set(clone, `data.home.formations[${snapshotName}]`, _.cloneDeep(clone.data.home?.formations?.[snapshot]));
      _.set(clone, `data.away.formations[${snapshotName}]`, _.cloneDeep(clone.data.away?.formations?.[snapshot]));

      clone.snapshot = snapshotName;

      storageMatchUpdate({ match: clone.data }).then(() => {
        return storageMatchHistory(clone.data._id, action.type, { time: timeline, snapshot: action.snapshotName });
      });

      return clone;
    }

    case REMOVE_SNAPSHOT: {
      clone = _.cloneDeep(state);

      delete clone.data.home.positions[action.snapshotName];
      delete clone.data.away.positions[action.snapshotName];
      delete clone.current_positions.away[action.snapshotName];
      delete clone.current_positions.home[action.snapshotName];

      delete clone.data.home.formations[action.snapshotName];
      delete clone.data.away.formations[action.snapshotName];

      clone.snapshot = 'lineup';

      storageMatchUpdate({ match: clone.data }).then(() => {
        storageMatchHistory(clone.data._id, action.type, { time: timeline, snapshot: action.snapshotName });
      });

      return clone;
    }

    case REMOVE_PLAYER_STORY: {
      const {
        side, player_id, time, key, value,
      } = action.story;
      clone = _.cloneDeep(state);

      if (key !== 'notes' && key !== 'event_type' && key !== 'tags') {
        delete clone.data[side].values[player_id][time][key];
        if (_.size(clone.data[side].values[player_id][time]) === 2) {
          delete clone.data[side].values[player_id][time];
        }

        storageEvaluationsDelete({
          side,
          time,
          player_id,
          key,
          match_id: clone.data._id,
        }).then(() => {
          return storageMatchUpdate({ match: clone.data, skipServerUpdate: true });
        }).then(() => {
          return storageMatchHistory(clone.data._id, action.type, { time: timeline, player: player_id, key });
        });
      } else {
        const indicatorItem = clone.data[side].values[player_id][time];

        if (key === 'event_type') {
          const index = indicatorItem.event_type.findIndex((v) => v === value);
          indicatorItem.event_type = [...indicatorItem.event_type.slice(0, index), ...indicatorItem.event_type.slice(index + 1)];
        } else {
          clone.data[side].values[player_id][time].notes = '';
          clone.data[side].values[player_id][time].tags = [];
        }

        if (!indicatorItem.notes && !indicatorItem.event_type.length) {
          // delete evaluation
          storageEvaluationsDelete({
            side,
            time,
            player_id,
            key,
            match_id: clone.data._id,
          }).then(() => {
            return storageMatchUpdate({ match: clone.data, skipServerUpdate: true });
          }).then(() => {
            return storageMatchHistory(clone.data._id, action.type, { time: timeline, player: player_id, key });
          });
        } else {
          // update evaluation
          storageEvaluations({
            side,
            time,
            player_id,
            key,
            value: indicatorItem.notes,
            event_type: indicatorItem.event_type,
            match_id: clone.data._id,
            team_id: clone.data[side].team_id,
          }).then(() => {
            return storageMatchUpdate({ match: clone.data, skipServerUpdate: true });
          }).then(() => {
            return storageMatchHistory(clone.data._id, action.type, {
              side, time, player_id, control: key, value,
            });
          });
        }
      }

      return clone;
    }

    case CHANGE_FORMATION: {
      clone = _.cloneDeep(state);

      if (action.formation_id === _.get(state.data[action.side].formations[snapshot], 'id')) {
        return clone;
      }

      clone.data[action.side].formations[snapshot] = {
        ...action.formation,
        positions: state.data[action.side].formations[snapshot] ? state.data[action.side].formations[snapshot].positions.map((position, i) => ({
          ...action.formation.positions[i],
          player_id: position.player_id,
        }))
          : action.formation.positions,
      };

      const setNewFormationPositionValue = (oldPosition, newPosition) => {
        const positionClone = _.cloneDeep(oldPosition);

        if (newPosition.position_id_detail) { positionClone.position_id_detail = newPosition.position_id_detail; }
        if (newPosition.position_id) { positionClone.position_id = newPosition.position_id; }
        if (newPosition.top && newPosition.left) {
          positionClone.coordinates = {
            top: newPosition.top,
            left: newPosition.left,
          };
        }
        positionClone.active = true;

        return positionClone;
      };

      _.forEach(clone.data[action.side].formations[snapshot].positions, (position) => {
        const isPlayerActive = position.player_id && _.get(clone, `current_positions[${action.side}][${snapshot}][${position.player_id}].active`, false);

        if (isPlayerActive) {
          clone.current_positions[action.side][snapshot][position.player_id] = setNewFormationPositionValue(state.current_positions[action.side][snapshot][position.player_id], position);
          clone.data[action.side].positions[snapshot][position.player_id] = setNewFormationPositionValue(state.data[action.side].positions[snapshot][position.player_id], position);
          clone.data[action.side].positions[snapshot][position.player_id].event_id = clone.data.last_event_id;
          clone.data.last_event_id = ++clone.data.last_event_id;
        }
      });

      storageMatchUpdate({ match: clone.data }).then(() => {
        return storageMatchHistory(clone.data._id, action.type, { time: timeline, side: action.side, formation: action.formation.id });
      });
      return clone;
    }

    case CHANGE_FORMATION_POSITION: {
      clone = _.cloneDeep(state);

      const setFormationPositionValue = (position) => {
        const positionClone = _.size(position) ? _.cloneDeep(position) : { player_id: action.information.key };
        positionClone.active = true;
        positionClone.substitution = false;
        positionClone.position_id_detail = action.position.position_id_detail;
        positionClone.position_id = action.position.position_id;
        positionClone.coordinates = {
          top: action.top,
          left: action.left,
        };
        return positionClone;
      };

      if (!clone.current_positions[action.side]) {
        clone.current_positions[action.side] = { lineup: {} };
      }

      if (!clone.data[action.side].positions) {
        clone.data[action.side].positions = { lineup: {} };
      }

      if (!clone.data[action.side].formations) {
        clone.data[action.side].formations = { lineup: {} };
      }

      const from = _.get(state, `current_positions[${action.side}][${snapshot}][${action.information.key}]`, {});

      clone.current_positions[action.side][snapshot][action.information.key] = setFormationPositionValue(from);
      clone.data[action.side].positions[snapshot][action.information.key] = setFormationPositionValue(from);
      clone.data[action.side].positions[snapshot][action.information.key].event_id = clone.data.last_event_id;
      clone.data.last_event_id = ++clone.data.last_event_id;

      clone.data[action.side].formations[snapshot].positions = state.data[action.side].formations[snapshot].positions.map((position) => {
        if (position.player_id === action.information.key) {
          delete position.player_id;
        }
        if (position.position_id_detail === action.position.position_id_detail) {
          position.player_id = action.information.key;
        }
        return position;
      });

      storageMatchUpdate({ match: clone.data }).then(() => {
        storageMatchHistory(clone.data._id, action.type, { time: timeline, snapshot, player_id: action.information.key });
      });

      return clone;
    }

    case SELECT_FORMATION_POSITION: {
      return {
        ...state,
        positionSelected: {
          side: action.side,
          players: action.players,
          position: action.position,
        },
        substitutionSelected: {
          side: null,
          index: null,
        },
      };
    }

    case SELECT_SUBSTITUTION: {
      return {
        ...state,
        substitutionSelected: {
          side: action.side,
          index: action.index,
        },
        positionSelected: {
          side: null,
          players: [],
          position: {},
        },
      };
    }

    case SET_GOALS_FOR_PERIOD: {
      let homegoals;
      let awaygoals;
      let homegoals_ht;
      let awaygoals_ht;

      if (action.period === '1') {
        homegoals = state.data.homegoals || 0;
        awaygoals = state.data.awaygoals || 0;
        homegoals_ht = state.data.homegoals_ht || undefined;
        awaygoals_ht = state.data.awaygoals_ht || undefined;
      }

      if (+action.period > 1) {
        homegoals = state.data.homegoals || 0;
        awaygoals = state.data.awaygoals || 0;
        homegoals_ht = state.data.homegoals_ht || state.data.homegoals || 0;
        awaygoals_ht = state.data.awaygoals_ht || state.data.awaygoals || 0;
      }

      const newState = {
        ...state,
        data: {
          ...state.data,
          homegoals,
          awaygoals,
          homegoals_ht,
          awaygoals_ht,
        },
      };

      storageMatchUpdate({ match: newState.data }).then(() => {
        storageMatchHistory(newState.data._id, action.type, { time: timeline, snapshot });
      });

      return newState;
    }

    default:
      return state;
  }
}

function setOuterControlValue(action, state, value, last_event_id) {
  const valueClone = value ? { ...value } : { player_id: state.controls.player };

  valueClone[state.controls.inner] = action.value;
  valueClone.event_id = last_event_id;

  if (action.event_type) {
    valueClone.event_type = action.event_type;
  }

  if (action.tags) {
    valueClone.tags = action.tags;
  }

  return valueClone;
}
